<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-primary">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Artikelverwaltung</h4>
                            <p>Neuen Artikel erstellen</p>
                        </div>
                        <div class="card-tools">
                            <button type="button" class="btn btn-primary btn-sm" @click="saveArticle">
                                <i class="fas fa-save"></i> Speichern
                            </button>
                        </div>
                    </div>

                    <div class="card-body">
                        <fieldset class="p-2 border">
                            <legend class="w-auto">Stammdaten</legend>
                            <div class="row">
                                <div class="col-md-6">

                                    <div class="form-group row">
                                        <label for="name" class="control-label col-sm-3 col-form-label col-form-label-sm">Artikelname*</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" name="name" id="name" :class="{'is-invalid': form.errors.has('name')}" v-model="form.name">
                                            <has-error :form="form" field="name"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="ordernumber" class="control-label col-sm-3 col-form-label col-form-label-sm">Artikelnummer*</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" name="ordernumber" id="ordernumber" :class="{'is-invalid': form.errors.has('ordernumber')}" v-model="form.ordernumber">
                                            <has-error :form="form" field="ordernumber"></has-error>
                                        </div>
                                    </div>

                                    <!-- <div class="form-group row">
                                        <label for="groups" class="control-label col-sm-3 col-form-label col-form-label-sm">Warengruppe*</label>
                                        <div class="col-sm-8">
                                            <Select2 v-model="form.groups" :options="groups" :settings="{ multiple: true }" :class="{'is-invalid': form.errors.has('groups')}"/>
                                            <has-error :form="form" field="groups"></has-error>
                                        </div>
                                    </div> -->

                                    <div class="form-group row">
                                        <label for="seo_category" class="control-label col-sm-3 col-form-label col-form-label-sm">SEO-Kategorie*</label>
                                        <div class="col-sm-8">
                                            <Select2 v-model="form.seo_category" :options="seoCategories" :class="{'is-invalid': form.errors.has('seo_category')}" />
                                            <has-error :form="form" field="seo_category"></has-error>
                                        </div>
                                    </div>

                                    <!-- <div class="form-group row">
                                        <label for="active" class="control-label col-sm-3 col-form-label col-form-label-sm">Aktiv</label>
                                        <div class="col-sm-8">
                                            <input type="checkbox" class="form-check-input" name="active" id="active" :class="{'is-invalid': form.errors.has('active')}" v-model="form.active" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="active"></has-error>
                                        </div>
                                    </div> -->

                                    <div class="form-group row">
                                        <label for="active" class="control-label col-sm-3 col-form-label col-form-label-sm">Aktiv</label>
                                        <div class="col-sm-8 custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchActive" :class="{'is-invalid': form.errors.has('active')}" v-model="form.active">
                                            <label class="custom-control-label" for="customSwitchActive"></label>
                                            <has-error :form="form" field="active"></has-error>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-md-6">

                                    <div class="form-group row">
                                        <label for="producer_id" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Hersteller Front*</label>
                                        <div class="col-sm-8">
                                            <select name="producer_id" id="producer_id" class="form-control form-control-sm" v-model="form.producer_id" :class="{'is-invalid': form.errors.has('producer_id')}">
                                                <option v-for="producer in producers" :key="producer.id" v-bind:value="producer.id">{{ producer.number }} &ndash; {{ producer.name  }}</option>
                                            </select>
                                            <has-error :form="form" field="producer_id"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="distributor_id" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Lieferant (Bestellung intern)*</label>
                                        <div class="col-sm-8">
                                            <select name="distributor_id_id" id="distributor_id_id" class="form-control form-control-sm" v-model="form.distributor_id" :class="{'is-invalid': form.errors.has('distributor_id')}">
                                                <option v-for="distributor in distributors" :key="distributor.id" v-bind:value="distributor.id">{{ distributor.number }} &ndash; {{ distributor.name  }}</option>
                                            </select>
                                            <has-error :form="form" field="distributor_id"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="tax_id" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">MwSt*</label>
                                        <div class="col-sm-8">
                                            <div v-for="tax in taxes" :key="tax.id" class="form-check-inline form-check">
                                                <input v-model="form.tax_id" :value="tax.id" type="radio" name="tax_id" id="tax_id" class="form-check-input" style="position: static; margin-left: 0;">
                                                <label  class="control-label form-check-label">{{tax.description}}</label>
                                            <has-error :form="form" field="tax_id"></has-error>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="han" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Lieferantenartikelnr.</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" name="han" id="han" :class="{'is-invalid': form.errors.has('han')}" v-model="form.han">
                                            <has-error :form="form" field="han"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="ean" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">EAN</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" name="ean" id="ean" :class="{'is-invalid': form.errors.has('ean')}" v-model="form.ean">
                                            <has-error :form="form" field="ean"></has-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <div class="row">
                            <div class="col-md-6" style="display: flex; flex-direction: column">
                                <fieldset class="p-2 mt-3 border" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                                    <legend class="w-auto">Artikeleigenschaften</legend>

                                    <div class="form-group row" style="color: #499360;">
                                        <label for="demeter" class="control-label col-sm-3 col-form-label col-form-label-sm">Demeter</label>
                                        <div class="col-sm-8">
                                            <input type="checkbox" class="form-check-input" name="demeter" id="demeter" :class="{'is-invalid': form.errors.has('demeter')}" v-model="form.demeter" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="demeter"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group input-group-sm row">
                                        <label for="bio" class="control-label col-sm-3 col-form-label col-form-label-sm">Bio</label>
                                        <div class="col-sm-8">
                                            <input type="checkbox" class="form-check-input" name="bio" id="bio" :class="{'is-invalid': form.errors.has('bio')}" v-model="form.bio" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="bio"></has-error>
                                        </div>
                                        <template v-if="form.bio == 1">
                                            <label for="bio" class="control-label col-sm-4 col-form-label col-form-label-sm">Kontrollnummer: </label>
                                            <div class="col-sm-7" >
                                                <input type="text" class="form-control form-control-sm" v-model="form.bio_controllnumber" :class="{'is-invalid': form.errors.has('bio_controllnumber')}"/>
                                                <has-error :form="form" field="bio_controllnumber"></has-error>
                                            </div>
                                        </template>

                                    </div>

                                    <div class="form-group row">
                                        <label for="vegan" class="control-label col-sm-3 col-form-label col-form-label-sm">Vegan</label>
                                        <div class="col-sm-8">
                                            <input type="checkbox" class="form-check-input" name="vegan" id="vegan" :class="{'is-invalid': form.errors.has('vegan')}" v-model="form.vegan" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="vegan"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group row" style="color: #499360;">
                                        <label for="vegetarisch" class="control-label col-sm-3 col-form-label col-form-label-sm">Vegetarisch</label>
                                        <div class="col-sm-8">
                                            <input type="checkbox" class="form-check-input" name="vegetarisch" id="vegetarisch" :class="{'is-invalid': form.errors.has('vegetarisch')}" v-model="form.vegetarisch" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="vegetarisch"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="glutenfrei" class="control-label col-sm-3 col-form-label col-form-label-sm">Glutenfrei</label>
                                        <div class="col-sm-8">
                                            <input type="checkbox" class="form-check-input" name="glutenfrei" id="glutenfrei" :class="{'is-invalid': form.errors.has('glutenfrei')}" v-model="form.glutenfrei" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="glutenfrei"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="laktosefrei" class="control-label col-sm-3 col-form-label col-form-label-sm">Laktosefrei</label>
                                        <div class="col-sm-8">
                                            <input type="checkbox" class="form-check-input" name="laktosefrei" id="laktosefrei" :class="{'is-invalid': form.errors.has('laktosefrei')}" v-model="form.laktosefrei" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="laktosefrei"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row" style="color: #499360;">
                                        <label for="xxl" class="control-label col-sm-3 col-form-label col-form-label-sm">Auch in XXL</label>
                                        <div class="col-sm-8">
                                            <input type="checkbox" class="form-check-input" name="xxl" id="xxl" :class="{'is-invalid': form.errors.has('xxl')}" v-model="form.xxl" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="xxl"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group row" style="color: #499360;">
                                        <label for="potatos" class="control-label col-sm-3 col-form-label col-form-label-sm">Bei Kartoffeln</label>
                                        <div class="col-sm-8">
                                            <select name="potatos" id="potatos" v-model="form.potato_property" class="form-control form-control-sm">
                                                <option :value="null">-- Bitte wählen -- </option>
                                                <option value="festkochend">festkochend</option>
                                                <option value="vorwiegend_festkochend">vorwiegend festkochend</option>
                                                <option value="mehlig">mehlig</option>
                                            </select>
                                            <has-error :form="form" field="xxl"></has-error>
                                        </div>
                                    </div>


                                    <div class="my-4">
                                        <b>Bei Obst & Gemüse:</b> 
                                    </div>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group"> 
                                                <label for="fruit_property_type">Typ</label>
                                                <select class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('fruit_property_type')}" name="fruit_property_type" id="fruit_property_type" v-model="form.fruit_property_type">
                                                    <option :value="null">-- Bitte wählen --</option>
                                                    <option :value="'size'">Größe</option>
                                                    <option :value="'reifegrad'">Reifegrad</option>
                                                </select>
                                                <has-error :form="form" field="fruit_property_type"></has-error>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label for="fruit_property">Größe / Reifegrad / Farbe / ...:</label>
                                                <input type="text" class="form-control form-control-sm" v-model="form.fruit_property" :class="{'is-invalid': form.errors.has('fruit_property')}">
                                                <has-error :form="form" field="fruit_property"></has-error>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="article_commercial_class_id" class="control-label col-sm-3 col-form-label col-form-label-sm">Handelsklasse:</label>
                                        <div class="col-sm-8">
                                            <select name="article_commercial_class_id" id="article_commercial_class_id" class="form-control form-control-sm" v-model="form.article_commercial_class_id" :class="{'is-invalid': form.errors.has('article_commercial_class_id')}">
                                                <option selected value="">-keine-</option>
                                                <option v-for="classes in classes" :key="classes.id" v-bind:value="classes.id">{{classes.name}}</option>
                                            </select>
                                            <has-error :form="form" field="article_commercial_class_id"></has-error>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>

                            <div class="col-md-6" style="display: flex; flex-direction: column">
                                <fieldset class="p-2 mt-3 border" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                                    <legend class="w-auto">Versandeigenschaften</legend>

                                    <div class="form-group row" v-if="funke_mode == 'false'">
                                        <label for="fragile" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">Bruchgut je Artikel (4,50&euro;)*</label>
                                        <div class="col-sm-7">
                                            <input type="checkbox" class="form-check-input" name="fragile" id="fragile" :class="{'is-invalid': form.errors.has('fragile')}" v-model="form.fragile" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="fragile"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row" v-if="funke_mode == 'false'">
                                        <label for="cargo" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">Sperrgut je Artikel (6,90&euro;)*</label>
                                        <div class="col-sm-7">
                                            <input type="checkbox" class="form-check-input" name="cargo" id="cargo" :class="{'is-invalid': form.errors.has('cargo')}" v-model="form.cargo" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="cargo"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="cool" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">Kühlversand pauschal (4,50&euro;)*</label>
                                        <div class="col-sm-7">
                                            <input type="checkbox" class="form-check-input" name="cool" id="cool" :class="{'is-invalid': form.errors.has('cool')}" v-model="form.cool" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="cool"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="shippingfree" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">Versandfrei</label>
                                        <div class="col-sm-7">
                                            <input type="checkbox" class="form-check-input" name="shippingfree" id="shippingfree" :class="{'is-invalid': form.errors.has('shippingfree')}" v-model="form.shippingfree" style="position: static; margin-left: 0;">
                                            <!-- <select name="shippingfree" id="shippingfree" class="form-control form-control-sm" v-model="form.shippingfree" :class="{'is-invalid': form.errors.has('shippingfree')}">
                                                <option value="0">Nein</option>
                                                <option value="1">Ja</option>
                                            </select> -->
                                            <has-error :form="form" field="shippingfree"></has-error>
                                        </div>
                                    </div>

                                </fieldset>
                            </div>



                        </div>
                        

                        


                        <fieldset class="p-2 mt-3 border">
                            <legend class="w-auto">Preispflege</legend>

                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="price" class="control-label col-form-label col-form-label-sm">Preis (Brutto)*</label>
                                        <input type="number" class="form-control form-control-sm" name="price" id="price" :class="{'is-invalid': form.errors.has('price')}" v-model="form.price">
                                        <has-error :form="form" field="price"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="pseudo_price" class="control-label col-form-label col-form-label-sm">Aktionspreis (Brutto)</label>
                                        <input type="number" class="form-control form-control-sm" name="pseudo_price" id="pseudo_price" :class="{'is-invalid': form.errors.has('pseudo_price')}" v-model="form.pseudo_price">
                                        <has-error :form="form" field="pseudo_price"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="purchase_price" class="control-label col-form-label col-form-label-sm">Einkaufspreis (Netto)</label>
                                        <input type="number" class="form-control form-control-sm" name="purchase_price" id="purchase_price" :class="{'is-invalid': form.errors.has('purchase_price')}" v-model="form.purchase_price">
                                        <has-error :form="form" field="purchase_price"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="store_price" class="control-label col-form-label col-form-label-sm">Filialpreis</label>
                                        <input type="number" class="form-control form-control-sm" name="store_price" id="store_price" :class="{'is-invalid': form.errors.has('store_price')}" v-model="form.store_price">
                                        <has-error :form="form" field="store_price"></has-error>
                                    </div>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="deposit_id" class="control-label col-form-label col-form-label-sm">Pfandart*</label>
                                            <select name="deposit_id" id="deposit_id" class="form-control form-control-sm" v-model="form.deposit_id" :class="{'is-invalid': form.errors.has('deposit_id')}">
                                                <option v-for="deposit in deposits" :key="deposit.id" v-bind:value="deposit.id">{{deposit.name}}</option>
                                            </select>
                                            <has-error :form="form" field="deposit_id"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="deposit" class="control-label col-form-label col-form-label-sm">Pfandsumme</label>
                                        <input type="text" class="form-control form-control-sm" name="deposit" id="deposit" :class="{'is-invalid': form.errors.has('deposit')}" v-model="form.deposit">
                                        <has-error :form="form" field="deposit"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-3"></div><div class="col-md-3"></div>

                            </div>   

                            <div class="row">
                                <div class="col-md-3">    
                                    <div class="form-group">
                                        <label for="article_unit_id" class="control-label col-form-label col-form-label-sm">Grundeinheit*</label>
                                            <select name="article_unit_id" id="article_unit_id" class="form-control form-control-sm" v-model="form.article_unit_id" :class="{'is-invalid': form.errors.has('article_unit_id')}">
                                                <option v-for="unit in units" :key="unit.id" v-bind:value="unit.id">{{unit.description}}</option>
                                            </select>
                                            <has-error :form="form" field="article_unit_id"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="purchaseunit" class="control-label col-form-label col-form-label-sm">Verpackung</label>
                                            <input type="text" class="form-control form-control-sm" name="purchaseunit" id="purchaseunit" :class="{'is-invalid': form.errors.has('purchaseunit')}" v-model="form.purchaseunit">
                                            <has-error :form="form" field="purchaseunit"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3">  
                                            <div class="form-group">
                                                <label for="referenceunit" class="control-label col-form-label col-form-label-sm">
                                                    Referenz                                                
                                                </label>
                                                <input type="text" class="form-control form-control-sm" name="referenceunit" id="referenceunit" disabled :class="{'is-invalid': form.errors.has('referenceunit')}" v-model="form.referenceunit">
                                                <has-error :form="form" field="referenceunit"></has-error>
                                            </div>
                                        </div>
                            </div>   

                            <!-- <div class="row">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Kundengruppe</th>
                                            <th scope="col">Preis</th>
                                            <th scope="col">Pseudopreis</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="group in customerGroups" :key="group.id">
                                            <td>{{group.description}}</td>
                                            <td><input type="number" :name="'prices.'+group.id" :id="'prices.'+group.id" :class="{'is-invalid': form.errors.has('prices.' + group.id)}" v-model="prices[group.id]"><has-error :form="form" :field="'prices.' + group.id"></has-error></td>
                                            <td><input type="number" :name="'pseudos.'+group.id" :id="'pseudos.'+group.id" :class="{'is-invalid': form.errors.has('pseudos.' + group.id)}" v-model="pseudos[group.id]"><has-error :form="form" :field="'pseudos.' + group.id"></has-error></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->

                        </fieldset>

                        <fieldset class="p-2 mt-3 border">
                            <legend class="w-auto">Produktpflege</legend>
                            <div class="row">
                                <div class="col-md-3">

                                    <!-- <div class="form-group row">
                                        <label for="maxpurchase" class="control-label col-sm-3 col-form-label col-form-label-sm">Maximalabnahme</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" name="maxpurchase" id="maxpurchase" :class="{'is-invalid': form.errors.has('maxpurchase')}" v-model="form.maxpurchase">
                                            <has-error :form="form" field="maxpurchase"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="minpurchase" class="control-label col-sm-3 col-form-label col-form-label-sm">Mindestabnahme</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" name="minpurchase" id="minpurchase" :class="{'is-invalid': form.errors.has('minpurchase')}" v-model="form.minpurchase">
                                            <has-error :form="form" field="minpurchase"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="minstock" class="control-label col-sm-3 col-form-label col-form-label-sm">Lager-Mindestbestand</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" name="minstock" id="minstock" :class="{'is-invalid': form.errors.has('minstock')}" v-model="form.minstock">
                                            <has-error :form="form" field="minstock"></has-error>
                                        </div>
                                    </div> -->


                                    <div class="form-group row">
                                        <label for="laststock" class="control-label col-sm-6 col-form-label col-form-label-sm">Aus dem Sortiment
                                        <i class="fas fa-question-circle" id="tooltip-ads"></i>
                                            <b-tooltip target="tooltip-ads" triggers="hover">
                                                Artikel geht aus dem Sortiment &ndash; in der Front "letzte Chance". Bei Nullbestand Artikel löschen! 
                                            </b-tooltip>  
                                        </label>
                                        <div class="col-sm-6">
                                            <input type="checkbox" class="form-check-input" name="laststock" id="laststock" :class="{'is-invalid': form.errors.has('laststock')}" v-model="form.laststock" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="laststock"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="stock" class="control-label col-sm-6 col-form-label col-form-label-sm">Bestandspflege
                                        <!-- <i class="fas fa-question-circle" id="tooltip-stock"></i>
                                            <b-tooltip target="tooltip-stock" triggers="hover">
                                                Nur bei der Nutzung der Checkbox "AdS" 
                                            </b-tooltip>   -->
                                        </label>
                                        <div class="col-sm-5">
                                            <input type="text" class="form-control form-control-sm" name="stock" id="stock" :class="{'is-invalid': form.errors.has('stock')}" v-model="form.stock">
                                            <has-error :form="form" field="stock"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="not_available" class="control-label col-sm-6 col-form-label col-form-label-sm">Kurzfristig nicht lieferbar
                                            <i class="fas fa-question-circle" id="tooltip-not-available"></i>
                                            <b-tooltip target="tooltip-not-available" triggers="hover">
                                                Zur Zeit nicht lieferbar - in der Font ist der Artikel nicht mehr bestellbar (Button deaktiviert), wird aber trotzdem noch angezeigt
                                            </b-tooltip>  
                                        </label>
                                        <div class="col-sm-6">
                                            <input type="checkbox" class="form-check-input" name="not_available" id="not_available" :class="{'is-invalid': form.errors.has('not_available')}" v-model="form.not_available" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="not_available"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="not_discountable" class="control-label col-sm-6 col-form-label col-form-label-sm">Nicht Rabattfähig
                                            <i class="fas fa-question-circle" id="tooltip-not_discountable"></i>
                                            <b-tooltip target="tooltip-not_discountable" triggers="hover">
                                                Der Artikel kann z. B. durch einen Coupon nicht rabattiert werden!
                                            </b-tooltip> 
                                        </label>
                                        <div class="col-sm-6">
                                            <input type="checkbox" class="form-check-input" name="not_discountable" id="not_discountable" :class="{'is-invalid': form.errors.has('not_discountable')}" v-model="form.not_discountable" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="not_discountable"></has-error>
                                        </div>
                                    </div>
                                
                                </div>
                                <div class="col-md-3">

                                    <div class="form-group row">
                                        <label for="topseller" class="control-label col-sm-6 col-form-label col-form-label-sm">Artikel hervorheben?</label>
                                        <div class="col-sm-6">
                                            <input type="checkbox" class="form-check-input" name="topseller" id="topseller" :class="{'is-invalid': form.errors.has('topseller')}" v-model="form.topseller" style="position: static; margin-left: 0;">
                                            <!-- <select name="topseller" id="topseller" class="form-control form-control-sm" v-model="form.topseller" :class="{'is-invalid': form.errors.has('topseller')}">
                                                <option value="0">Nein</option>
                                                <option value="1">Ja</option>
                                            </select> -->
                                            <has-error :form="form" field="topseller"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="seasonal" class="control-label col-sm-6 col-form-label col-form-label-sm">Saisonartikel</label>
                                        <div class="col-sm-6">
                                            <input type="checkbox" class="form-check-input" name="seasonal" id="seasonal" :class="{'is-invalid': form.errors.has('seasonal')}" v-model="form.seasonal" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="seasonal"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="sample_picture" class="control-label col-sm-6 col-form-label col-form-label-sm">Musterbild</label>
                                        <div class="col-sm-6">
                                            <input type="checkbox" class="form-check-input" name="sample_picture" id="sample_picture" :class="{'is-invalid': form.errors.has('sample_picture')}" v-model="form.sample_picture" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="sample_picture"></has-error>
                                        </div>
                                    </div>                                   

                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="shippingtime" class="control-label col-sm-3 offset-md-1 col-form-label col-form-label-sm">
                                            Lieferzeit (in Tagen) 
                                            <i class="fas fa-question-circle" v-b-tooltip.hover title="Nur die Anzahl der Tage eintragen (z. B. 1 oder 1-4) Voreingestellt: 1-4 Tage"></i>
                                        </label>
                                        <div class="col-sm-8">
                                            <input placeholder="1 - 4" type="text" class="form-control form-control-sm" name="shippingtime" id="shippingtime" :class="{'is-invalid': form.errors.has('shippingtime')}" v-model="form.shippingtime">
                                            <has-error :form="form" field="shippingtime"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="publishDate" class="control-label col-sm-3 offset-md-1 col-form-label col-form-label-sm">Einstelldatum
                                            <i class="fas fa-question-circle" id="tooltip-publishDate"></i>
                                            <b-tooltip target="tooltip-publishDate" triggers="hover">
                                                Ab diesem Tag, ist der Artikel in der Front sichtbar. Allerdings kann der Artikel erst bestellt werden, wenn der Artikel lieferbar ist ("Lieferbar ab").
                                            </b-tooltip> 
                                        </label>
                                        <div class="col-sm-8">
                                            <input type="date" class="form-control form-control-sm" name="publishDate" id="publishDate" :class="{'is-invalid': form.errors.has('publishDate')}" v-model="form.publishDate">
                                            <has-error :form="form" field="publishDate"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="releaseDate" class="control-label col-sm-3 offset-md-1 col-form-label col-form-label-sm">Lieferbar ab
                                            <i class="fas fa-question-circle" id="tooltip-releaseDate"></i>
                                            <b-tooltip target="tooltip-releaseDate" triggers="hover">
                                                3 Tage vor diesem Datum ist der Artikel bestellbar. (Funktioniert in Kombination mit dem Einstelldatum)
                                            </b-tooltip> 
                                        </label>
                                        <div class="col-sm-8">
                                            <input type="date" class="form-control form-control-sm" name="releaseDate" id="releaseDate" :class="{'is-invalid': form.errors.has('releaseDate')}" v-model="form.releaseDate">
                                            <has-error :form="form" field="releaseDate"></has-error>
                                        </div>
                                    </div>



                                </div>

                                <!-- <div class="col-md-6">

                                    

                                    

                                    <div class="form-group row">
                                        <label for="laststock" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Abverkauf?</label>
                                        <div class="col-sm-8">
                                            <select name="laststock" id="laststock" class="form-control form-control-sm" v-model="form.laststock" :class="{'is-invalid': form.errors.has('laststock')}">
                                                <option value="0">Nein</option>
                                                <option value="1">Ja</option>
                                            </select>
                                            <has-error :form="form" field="laststock"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="weight" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Gewicht:</label>
                                        <div class="col-sm-8">
                                            <input type="text" name="weight" id="weight" class="form-control form-control-sm" v-model="form.weight" :class="{'is-invalid': form.errors.has('weight')}">
                                            <has-error :form="form" field="weight"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="width" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Breite:</label>
                                        <div class="col-sm-8">
                                            <input type="text" name="width" id="width" class="form-control form-control-sm" v-model="form.width" :class="{'is-invalid': form.errors.has('width')}">
                                            <has-error :form="form" field="width"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="length" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Länge</label>
                                        <div class="col-sm-8">
                                            <input type="text" name="length" id="length" class="form-control form-control-sm" v-model="form.length" :class="{'is-invalid': form.errors.has('length')}">
                                            <has-error :form="form" field="length"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="height" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Höhe</label>
                                        <div class="col-sm-8">
                                            <input type="text" name="height" id="height" class="form-control form-control-sm" v-model="form.height" :class="{'is-invalid': form.errors.has('height')}">
                                            <has-error :form="form" field="height"></has-error>
                                        </div>
                                    </div>


                                </div> -->


                            </div>
                        </fieldset>

                        <fieldset class="p-2 mt-3 border">
                            <legend class="w-auto">Beschreibung</legend>
                            <div class="form-group">
                                <label for="description" class="control-label col-sm-2 col-form-label col-form-label-sm">Beschreibung</label>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.description_long" :class="{'is-invalid': form.errors.has('description_long')}"></ckeditor>
                                <has-error :form="form" field="description_long"></has-error>
                            </div>
                        </fieldset>


                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';


export default {
    name: 'articles.create',
    title: 'Neuer Artikel',
    data() {
        return {
            funke_mode: process.env.VUE_APP_FUNKE_MODE, 
            form: new window.Form({
                id: "",
                producer_id: "",
                distributor_id: "",
                name: "",
                ordernumber: "",
                groups: [
                    "1",
                ],
                seo_category: "",
                tax_id: "",
                han: "",
                ean: "",
                description_long: "",
                article_unit_id: "",
                article_commercial_class_id: "",
                purchaseunit: "",
                referenceunit: "1",
                deposit_id: "1",
                deposit: "",
                fragile:"",
                cargo:"",
                cool:"",
                maxpurchase:"",
                minpurchase:"",
                minstock:"",
                shippingtime:"",
                publishDate:"",
                releaseDate:"",
                shippingfree:"",
                topseller:"",
                laststock:"",
                weight:"",
                width:"",
                height:"",
                length:"",
                price: "",
                pseudo_price: "",
                purchase_price: "",
                not_available: "", 
                active: false, 
                // prices: {},
                // pseudos: {},
                bio: "",
                bio_controllnumber: null,
                vegan: "",
                vegetarisch: false,
                glutenfrei: "",
                laktosefrei: "",
                seasonal: "", 
                sample_picture: "", 
                store_price: "", 
                not_discountable: "", 
                stock: "", 
                hofmarke: "",
                bundle: 0,
                voucher: 0,
                xxl: "", 
                demeter: "", 
                country: "", 
                fruit_property: null,
                fruit_property_type: null,
                potato_property: null, 
            }),
            distributors: [],
            producers: [],
            taxes: [],
            units: [],
            classes: [],
            deposits: [],
            categories: [],
            groups: [],
            seoCategories: [],
            customerGroups: [],
            // prices: {},
            // pseudos: {},
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }

            },
        }
    },
    watch: {
        'form.bio': function() {
            if(this.form.bio == 0 || this.form.bio == false)
            {
                this.form.bio_controllnumber = null;
            }
        },
    },

    methods: {

        saveArticle(){
            this.$Progress.start();
            // this.form.prices = this.prices;
            // this.form.pseudos = this.pseudos;
            this.form
                .post("/articles")
                .then((res) => {
                    this.$swal({
                        icon: "success",
                        title: "Artikel erfolgreich hinzugefügt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.$router.push({name: 'articles.details', params: {id: res.data.data.id}});
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadCategories() {
            this.$Progress.start();
            this.axios
                .get("/categoriesArticleList")
                .then((response) => {
                    this.categories = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadSeoCategories() {
            this.$Progress.start();
            this.axios
                .get("/seocategories")
                .then((response) => {
                    this.seoCategories = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadCustomerGroups() {
            this.$Progress.start();
            this.axios
                .get("/customer-groups")
                .then((response) => {
                    this.customerGroups = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },


        loadDeposits() {
            this.$Progress.start();
            this.axios
                .get("/deposits")
                .then((response) => {
                    this.deposits = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadUnits() {
            this.$Progress.start();
            this.axios
                .get("/units")
                .then((response) => {
                    this.units = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadClasses() {
            this.axios
                .get("/classes")
                .then((response) => {
                    this.classes = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadDistributors() {
            this.$Progress.start();
            this.axios
                .get("/distributors",{ 
                    params: {
                        per_page: 150
                    }
                })
                .then((response) => {
                    this.distributors = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadProducers() {
            this.$Progress.start();
            this.axios
                .get("/producers", {
                    params:{
                        per_page: 1000,
                    }
                })
                .then((response) => {
                    this.producers = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadTaxes() {
            this.$Progress.start();
            this.axios
                .get("/taxes")
                .then((response) => {
                    this.taxes = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadGroups() {
            this.$Progress.start();
            this.axios
                .get("/groupsArticleList")
                .then((response) => {
                    this.groups = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
    },

    created() {
        this.loadProducers();
        this.loadDistributors();
        this.loadTaxes();
        this.loadUnits();
        this.loadClasses(); 
        this.loadDeposits();
        this.loadCustomerGroups();
        this.loadCategories();
        this.loadSeoCategories();
        this.loadGroups();
    },
}
</script>

<style>

</style>